import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import Body from './comps/body/Body';
import React from 'react';
import './index.css';

import './assets/styles/Form.css';
import './assets/styles/Table.css';
import './assets/styles/Cont.css';
import './assets/styles/Chart.css';

import Home from './pages/home/Home';
import Projects from './pages/projects/Projects';
import Tickets from './pages/tickets/Tickets';
import CreateTicket from './pages/tickets/create/CreateTicket';
import Settings from './pages/settings/Settings';
import Team from './pages/team/Team';
import Wiki from './pages/wiki/Wiki';
import Customers from './pages/customers/Customers';
import CreateArticle from './pages/wiki/createArticle/CreateArticle';
import Worker from './pages/worker/Worker';
import Article from './pages/wiki/article/Article';
import CategoryArticles from './pages/wiki/categoryArticles/CategoryArticles';

import AuthManager from './comps/other/AuthManager';
import NotFound from './comps/notFound/NotFound';
import Lizard from './pages/lizard/Lizard';
import Expenses from './pages/expenses/Expenses';

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Body />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "customers",
        element: <Customers />
      },
      {
        path: "lizard",
        element: <Lizard />
      },
      {
        path: "expenses",
        element: <Expenses />
      },
      {
        path: "projects",
        element: <Projects />
      },
      {
        path: "tickets",
        element: <Tickets />
      },
      {
        path: "tickets/new",
        element: <CreateTicket />
      },
      {
        path: "settings",
        element: <Settings />
      },
      {
        path: "team",
        element: <Team />
      },
      {
        path: "wiki",
        element: <Wiki />,
      },
      {
        path: "wiki/create-article",
        element: <CreateArticle />
      },
      {
        path: "wiki/category/:Id",
        element: <CategoryArticles />
      },
      {
        path: "wiki/article/:Id",
        element: <Article />
      },
      {
        path: "worker-service",
        element: <Worker />
      },
    ]
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthManager>
        <RouterProvider router={routes} />
    </AuthManager>
  </React.StrictMode>
);