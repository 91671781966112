import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function CreateTicket() {

    const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState(null);
    const [ticket, setTicket] = useState({});

    useEffect(() => {
        async function fetchData() {
            // const response = await CallApi('GET', '/Tickets/GetTemplate', null);
            setData("response");
            // console.log(response);
        }

        return () => fetchData()
    }, [])

    async function OnSubmit(e) {
        e.preventDefault();
        console.log(ticket)
        // const response = await CallApi('POST', '/Tickets/CreateTicket?userId=' + userID, ticket);
    
        // if (response !== null) {
        //     console.log(response);
        //     navigate('/tickets');
        // }
    }

    function ChangeValue(e) {
        const { name, value } = e.target;

        setTicket((newTicket) => ({
            ...newTicket,
            [name]: name === 'AssignedTo' || name === 'ProjectId' ? parseInt(value, 10) : value,
        }));
    }

    return (
        <>
            <button onClick={() => setIsOpen(true)} className="btn-primary">Create Ticket</button>

            { isOpen && (
                <div className="card">
                    <form onSubmit={OnSubmit}>
                        <div className="flex-row">
                            <label>
                                <span>Ticket Name:</span>
                                <input type="text" name="Name" onChange={ChangeValue} placeholder="Name" required />
                            </label>

                            <label>
                                <span>Project:</span>
                                <select name="ProjectId" onChange={ChangeValue}>
                                    <option selected disabled>Select</option>
                                    {data && data.Projects.map((item, index) => (
                                        <option key={index} value={item.Id}>{item.Name}</option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        <div className="flex-row">
                            <label>
                                <span>Deadline:</span>
                                <input type="date" name="Deadline" onChange={ChangeValue} min={new Date().toISOString().split('T')[0]} required />
                            </label>

                            <label>
                                <span>Assigned to:</span>
                                <select name="AssignedTo" defaultValue="" onChange={ChangeValue}>
                                    <option selected disabled>Select</option>
                                    {data && data.Users.map((item, index) => (
                                        <option key={index} value={item.Id}>{item.Fullname}</option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        <div className="flex-row">
                            <label>
                                <span>Description:</span>
                                <textarea name="Description" onChange={ChangeValue} required />
                            </label>
                        </div>

                        <div className="flex-end">
                            <Link to={"/tickets"} className="btn-secondary">Cancel</Link>

                            <button className="btn-primary">Create</button>
                        </div>

                    </form>
                </div>
            )}
        </>
    )
}

export default CreateTicket;