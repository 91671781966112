import './Lizard.css';

export default function Lizard() {
    return (
        <section className='lizard'>
            
            <video width="320" height="240" controls>
                <source src="https://www.youtube.com/live/jfKfPfyJRdk?si=holBhWG0en6RlmNe" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </section>
    )
}