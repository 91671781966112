import { Icon } from "@iconify-icon/react";
import { Link } from "react-router-dom";
import "./Header.css";

export default function Header({ isOpen, toogleAside }) {

    function onLogout() {
        localStorage.removeItem(process.env.REACT_APP_AUTH);
        window.location.reload(false);
    }

    return (
        <header>
            <button onClick={() => toogleAside()} className="btn">
                {isOpen ?
                    <Icon icon="mdi:close" width="30" height="30" style={{color: '#495057' }} />
                    :
                    <Icon icon="mdi:menu" width="30" height="30" style={{ color: '#495057' }} />
                }
            </button>

            <div className="header-btns">
                <Link className="btn" to={"/lizard"}>
                    <Icon icon="fluent-emoji-high-contrast:lizard" width="18" height="18" style={{ color: '#495057' }} />
                </Link>

                <Link className="btn" to={"/settings"}>
                    <Icon icon="mdi:settings-outline" width="18" height="18" style={{ color: '#495057' }} />
                </Link>

                <Link className="btn" onClick={() => onLogout()}>
                    <Icon icon="mdi:logout" width="18" height="18" style={{ color: '#495057' }} />
                </Link>

                <button className="profile">
                    <img src="https://randomuser.me/api/portraits/lego/2.jpg" alt="Profile Pic" />
                </button>
            </div>
        </header>
    )
}