import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
} from "chart.js";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import PageTitle from "../../comps/pageTitle/PageTitle";

function Home() {

    ChartJS.register(
        ArcElement,
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const [dashboard, setDashboard] = useState(null);

    useEffect(() => {
        async function fetchData() {
            // const response = await CallApi('GET', '/Data/GetDashboardData?UserId=' + userId, null);
            setDashboard(null);
        }

        return () => fetchData()
    }, [])

    return (
        <section>
            <PageTitle title="Home" lastLink="/logout" lastTitle="Logout" />

            <div className="flex-row m-b">
                <div>
                    <h3>Welcome {dashboard && dashboard.User.Fullname}</h3>
                    <h4>I hope you're having a good day</h4>
                </div>

                <div className="text-end">
                    <h4>{new Date().toDateString()}</h4>
                </div>
            </div>

            <div className="flex-body m-b">
                <div className="card">
                    <h4>Tickets:</h4>
                    {dashboard && <GetDoughnutChart label={"Tickets"} labels={["Incomplete", "Complete"]} data={[ dashboard.IncompleteTickets, dashboard.CompleteTickets ]} />}
                </div>
    
                <div className="card">
                    <h4>Data:</h4>
                    <br/>
                    <h5>Projects: {dashboard && dashboard.ProjectCount}</h5>
                    <br/>
                    <h5>Employes: {dashboard && dashboard.EmployesCount}</h5>
                    <br/>
                    <h5>My Open Tickets: {dashboard && dashboard.OpenTickets}</h5>
                    <br/>
                    <h5>My Open Todos: {dashboard && dashboard.CompleteTodos}</h5>
                </div>
            </div>
        </section>
    );
}

export default Home;

function GetDoughnutChart({ data, labels, label }) {
    
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: "# of " + label,
                data: data,
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                ],
                borderColor: [
                    "rgba(255, 99, 132, 1)", 
                    "rgba(54, 162, 235, 1)"
                ],
                borderWidth: 1,
            },
        ],
    };

    return <Doughnut data={chartData} />
}

// function GetLineChart() {

//     const labels = ["January", "February", "March", "April", "May", "June", "July"];

//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: "top",
//             },
//             title: {
//                 display: true,
//                 text: "Consasdf",
//             },
//         },
//     };

//     const linedata = {
//         labels,
//         datasets: [
//             {
//                 label: "Dataset 1",
//                 data: [5, 20, 10, 10, 19, 6, 3, 10, 6, 9],
//                 borderColor: "rgb(255, 99, 132)",
//                 backgroundColor: "rgba(255, 99, 132, 0.5)",
//             },
//             {
//                 label: "Dataset 1",
//                 data: [19, 5, 20, 10, 10, 6, 3, 10, 6, 9],
//                 borderColor: "rgb(255, 99, 132)",
//                 backgroundColor: "rgba(255, 99, 132, 0.5)",
//             },
//         ],
//     };

//     return <Line options={options} data={linedata} />
// }