import PageTitle from "../../../comps/pageTitle/PageTitle";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Icon } from "@iconify-icon/react";
import { GetArticlesByCategory } from "../../../comps/other/ApiManager";

export default function CategoryArticles() {
    const { Id } = useParams()
    const [articles, setArticles] = useState(null)

    useEffect(() => {
        async function fetchData() {
            const response = await GetArticlesByCategory(Id);
            setArticles(response)
        }

        return () => fetchData()
    }, [])

    return (
        <section>
            <PageTitle title="Category" lastLink="/wiki" lastTitle="Wiki" />

            <div className="flex m-b">
                <form className="search">
                    <label>
                        <input type="text" placeholder="Search..." className="inp-n" />
                    </label>
                    <button className="btn-primary">Search</button>
                </form>

                <Link to={"create-article"} className="btn-primary">Create Article</Link>
            </div>

            <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Name</th>
                                <th>Time</th>
                                <th>Create At</th>
                                <th>Viewed</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {articles && articles.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.time} Min</td>
                                    <td>{item.createAt}</td>
                                    <td>{item.viewCount}</td>
                                    <td>
                                        <Link to={"/wiki/article/" + item.articleId} className="btn-primary">
                                            <Icon icon="mdi:eye" width="18" height="18"  style={{color: 'white'}} />
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </section>
    )
}