import "./Footer.css";

export default function Footer() {
    return (
        <footer>
            <h5>Made With Love ❤️</h5>

            <h5>Powered by <a href="https://www.google.com/" target="_blank" rel="noreferrer">Inowiz</a></h5>
        </footer>
    )
}