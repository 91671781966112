import { Icon } from "@iconify-icon/react";
import { Link } from "react-router-dom";
import "./Aside.css";

export default function Aside({ isOpen }) {
    return !isOpen ? <></> :
    (
        <aside>
            <div className="logo">
                <h3>Valdo</h3>
            </div>
            <nav>
                <ul>
                    {NavData.links.map((item, index) => {
                        return item.isExternal ? 
                        <li key={index}>
                            <a href={item.link} target="_blank" rel="noreferrer">
                                {item.icon}
                                <span>{item.title}</span>
                            </a>
                        </li>
                        :
                        <li key={index}>
                            <Link to={item.link}>
                                {item.icon}
                                <span>{item.title}</span>
                            </Link>
                        </li>
                    })}
                </ul>
            </nav>
        </aside>
    )
}

export const NavData = {
    links: [
        {
            title: "Home",
            icon: <Icon icon="mdi:home" width="18" height="18" />,
            link: "/",
            isExternal: false,
        },
        {
            title: "Projects",
            icon: <Icon icon="mdi:application-brackets-outline" width="18" height="18" />,
            link: "/projects",
            isExternal: false,
        },
        {
            title: "Tickets",
            icon: <Icon icon="mdi:ticket-confirmation" width="18" height="18" />,
            link: "/tickets",
            isExternal: false,
        },
        {
            title: "Wiki",
            icon: <Icon icon="mdi:list-box-outline" width="18" height="18" />,
            link: "/wiki",
            isExternal: false,
        },
        {
            title: "Team",
            icon: <Icon icon="mdi:user-supervisor-circle" width="18" height="18" />,
            link: "/Team",
            isExternal: false,
        },
        {
            title: "Worker",
            icon: <Icon icon="mdi:wrench" width="18" height="18" />,
            link: "/worker-service",
            isExternal: false,
        },
        {
            title: "Ftp",
            icon: <Icon icon="mdi:sd-storage" width="18" height="18" />,
            link: "https://account.godaddy.com/products",
            isExternal: true,
        },
        {
            title: "Gitlab",
            icon: <Icon icon="mdi:cloud" width="18" height="18" />,
            link: "https://gitlab.com",
            isExternal: true,
        },
        {
            title: "Azure",
            icon: <Icon icon="mdi:storage" width="18" height="18" />,
            link: "https://portal.azure.com",
            isExternal: true,
        },
        {
            title: "Firebase",
            icon: <Icon icon="mdi:web" width="18" height="18" />,
            link: "https://console.firebase.google.com",
            isExternal: true,
        },
        {
            title: "GoDaddy",
            icon: <Icon icon="mdi:domain" width="18" height="18" />,
            link: "https://account.godaddy.com/products",
            isExternal: true,
        },
        {
            title: "Expenses",
            icon: <Icon icon="mdi:graph-line" width="18" height="18" />,
            link: "/expenses",
            isExternal: false,
        },
    ],
}