import "./Modal.css";

export default function Modal({ children }) {
    return (
        <div className="modal-cont">
            <div className="card">
                {children}
            </div>
        </div>
    )
}