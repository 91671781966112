import axios from "axios";
import { useState } from "react";

export default function Settings() {
    const [audioUrl, setAudioUrl] = useState(null);
    const [message, setMessage] = useState(null);

    async function fetchData() {
        try {
            const response = await axios.post(
                "https://api.elevenlabs.io/v1/text-to-speech/21m00Tcm4TlvDq8ikWAM",
                {
                    text: message,
                    model_id: "eleven_monolingual_v1",
                    // model_id: "VP5hzVm1tcYHcOiRPUVw",
                    voice_settings: {
                        stability: 0.5,
                        similarity_boost: 0.5,
                    },
                },
                {
                    headers: {
                        Accept: "audio/mpeg",
                        "Content-Type": "application/json",
                        "xi-api-key": "80e2e6dfc1451958610a3742f37ebe32",
                    },
                    responseType: "blob",
                }
            );

            const blob = new Blob([response.data], { type: 'audio/mpeg' });
            const url = window.URL.createObjectURL(blob);
            setAudioUrl(url);
        } catch (error) {
            console.error("Error fetching audio data:", error);
        }

        console.log("END");
    };

    const playAudio = () => {
        const audio = new Audio(audioUrl);
        audio.play().catch(error => {
            console.error('Error playing audio:', error);
        });
    };

    const downloadAudio = () => {
        const link = document.createElement('a');
        link.href = audioUrl;
        link.download = 'audio.mp3';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        fetchData();
    };

    return (
        <section>
            <div>
                <div className="card m-b">
                    <form onSubmit={onSubmit}>
                        <label>
                            <span>Message - {message && message}</span>
                            <input
                                type="text"
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Message"
                            />
                        </label>
                        <button className="btn-primary" type="submit">Send</button>
                    </form>
                </div>

                <div className="card">
                    <button onClick={playAudio} disabled={!audioUrl} className="btn-primary">Play Sound</button>

                    <button onClick={downloadAudio} disabled={!audioUrl} className="btn-primary">Download Sound</button>
                </div>
            </div>
        </section>
    );
}