import { ClearConfig, GetCorelId, GetToken } from "./TokenProvider";
import axios from "axios";

const Method = { Get: 'GET', Post: 'POST', Put: 'PUT', Delete: 'DELETE' }

const BaseRequest = { CorrelationId: GetCorelId() }

async function CallApi(method, endpoint, body = null, params = null) {
    try {
        const config = {
            method: method,
            url: `${process.env.REACT_APP_API_URI}${endpoint}`,
            headers: {
                'Authorization': GetToken(),
                'Content-Type': 'application/json',
            },
            data: body,
            params: params,
        }

        console.log(config)

        const response = await axios(config)
        .then((response) => response.data)
        .catch((error) => {
            console.log(error);
            
            if(error?.response?.status === 401)
                ClearConfig()
        })

        console.log(response);

        return response
    } catch (ex) {
        console.error(`Error! Status: ${ex}`);
        return null;
    }
}

export async function PostGetToken(credentials) {
    return await CallApi(Method.Post, '/Auth/GetToken', credentials);
}

export async function GetWorkerConfig() {
    return await CallApi(Method.Get, '/Config/GetAll', BaseRequest);
}

export async function SaveWorkerConfig(configs) {
    return await CallApi(Method.Post, '/Config/SaveConfigs', { ...BaseRequest, Configs: configs });
}

export async function GetOrgChart() {
    return await CallApi(Method.Get, '/User/GetOrgChart', null, BaseRequest);
}

export async function GetArticleCategories() {
    return await CallApi(Method.Get, '/Wiki/GetCategories', null, BaseRequest);
}

export async function GetMostViewedArticle(count) {
    return await CallApi(Method.Get, '/Wiki/GetMostViewedArticles', null, { ...BaseRequest, Count: count });
}

export async function GetProjects() {
    return await CallApi(Method.Get, '/Project/GetAll', null, BaseRequest);
}

export async function GetTickets() {
    return await CallApi(Method.Get, '/Ticket/GetAll', null, BaseRequest);
}

export async function GetArticlesByCategory(categoryId) {
    return await CallApi(Method.Get, '/Wiki/GetArticlesByCategory', null, { ...BaseRequest, CategoryId: categoryId });
}

export async function SaveArticle(article) {
    return await CallApi(Method.Post, '/Wiki/SaveArticle', null, { ...BaseRequest, ...article });
}