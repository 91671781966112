import { GetProjects } from "../../comps/other/ApiManager";
import { Badge } from "../../comps/other/General";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify-icon/react";
import CreateProject from "./create/CreateProject";
import PageTitle from "../../comps/pageTitle/PageTitle";

function Projects() {

    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const response = await GetProjects();
            setData(response);
        }

        return () => fetchData()
    }, [])

    return (
        <section>
            <PageTitle title="Projects" lastLink="/" lastTitle="Home" />

            <div className="flex m-b">
                <label>
                    <input type="text" placeholder="Search..." className="inp-n" />
                </label>

                <CreateProject />
            </div>

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Customer</th>
                            <th>Supervisor</th>
                            <th>Status</th>
                            <th>Gitlab</th>
                            <th>Is Alive?</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>

                        {data && data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {item.name}
                                </td>
                                <td>
                                    {item.customer.company}
                                </td>
                                <td>
                                    {item.supervisor.firstName + " " + item.supervisor.lastName}
                                </td>
                                <td>
                                        <Badge pack={1} type={item.status} />
                                </td>
                                <td>
                                    <a href="#id">{item.gitlabRepo ?? "None"}</a>
                                </td>
                                <td>
                                    <Badge pack={0} type={1} />
                                </td>
                                <td>
                                    <Link to={"/view/project/" + item.projectId} className="btn-primary">
                                        <Icon icon="mdi:eye" width="18" height="18" />
                                    </Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </section>
    )
}

export default Projects;