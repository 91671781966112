import { GetOrgChart } from "../../comps/other/ApiManager";
import PageTitle from "../../comps/pageTitle/PageTitle";
import { Icon } from "@iconify-icon/react";
import { useEffect, useState } from "react";
import "./Team.css"

export default function Team() {
    // const [orgChart] = useState({
    //     Title: "Šimon",
    //     Subtitle: "Team Leader",
    //     Childrens: [
    //         {
    //             Title: "Terka",
    //             Subtitle: "Full-Stack Developer",
    //             Childrens: null,
    //         },
    //         {
    //             Title: "Honza",
    //             Subtitle: "Full-Stack Developer",
    //             Childrens: null,
    //         },
    //         {
    //             Title: "Ondřej",
    //             Subtitle: "Backend Developer",
    //             Childrens: null,
    //         },
    //         {
    //             Title: "James",
    //             Subtitle: "UI/UX",
    //             Childrens: null,
    //         },
    //     ],
    // });

    const [orgChart, setOrgChart] = useState(null)

    async function fetchData() {
        const result = await GetOrgChart();
        console.log(result)
        setOrgChart(result)
    }

    useEffect(() =>{
        return () => fetchData()
    }, [])

    return orgChart === null || orgChart === undefined ? <></> : 
    (
        <section>
            <PageTitle title="Team" lastLink="/" lastTitle="Home" />

            <div className="card">
                <Chart data={orgChart} />
            </div>
        </section>
    )
}


function Chart({ data, isMain = true }) {
    const [isOpen, setIsOpen] = useState(true)    

    return (
        <div className="child">
            <ChartVerticalLine isMain={isMain} />

            <div className="main">
                <div className="main-text">
                    <h4>{data.title}</h4>
                    {data.subtitle !== null ? <p>{data.subtitle}</p> : <></>}
                    {Array.isArray(data.childrens) && data.childrens.length > 0
                        ? <button onClick={() => setIsOpen(!isOpen)} className="chart-arrow" >
                            {isOpen 
                                ? <Icon icon="mdi:keyboard-arrow-up" width="20" height="20" />
                                : <Icon icon="mdi:keyboard-arrow-down" width="20" height="20" />
                            }
                        </button>
                        : <></>
                    }
                </div>
            </div>


            {isOpen && Array.isArray(data.childrens) && data.childrens.length > 0
                ? <>
                    <ChartVerticalLine />

                    <ChartHorizontalLine childCount={data.childrens.length} />
                
                    <div className="childrens">
                        {Array.isArray(data.childrens)
                            ? data.childrens.map((child, i) => (
                                <Chart data={child} key={i} isMain={false} />
                            ))
                            : <></>
                        }
                    </div>
                </>
                : <></>
            }
        </div>
    )
}


function ChartHorizontalLine({ childCount }) {
    if (childCount <= 1) {
        return <></>
    } else {
        const countBlocks = (childCount * 2)
        const singleWidth = 100 / countBlocks
        const total = singleWidth * (countBlocks - 2)

        const styleWidth = `calc(${total}% + 3px)`;

        return (
            <div className="chart-line-cont">
                <div className="chart-hr-line" style={{ width: styleWidth }} />
            </div>
        )
    }
}

function ChartVerticalLine({ isMain = false }) {
    return isMain ? <></> :
    (
        <div className="chart-line-cont">
            <div className="chart-vr-line" />
        </div>
    )
}