import { Link } from "react-router-dom";
import "./NotFound.css";

function NotFound() {
    return (
        <main className="not-found">
            <div className="card">
                <h1 className="m-b">404</h1>
                <h2 className="m-b">NOT FOUND</h2>
                <h6>Path: {window.location.pathname}</h6>
                <Link to={'/'}>Home</Link>
            </div>
        </main>
    )
}

export default NotFound;