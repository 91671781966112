import { Badge, FormatDate } from "../../comps/other/General";
import { GetTickets } from "../../comps/other/ApiManager";
import PageTitle from "../../comps/pageTitle/PageTitle";
import CreateTicket from "./create/CreateTicket";
import { useEffect, useState } from "react";
import { Icon } from "@iconify-icon/react";
import { Link } from "react-router-dom";

export default function Tickets() {

    const [tickets, setTickets] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const response = await GetTickets();
            setTickets(response);
        }

        return () => fetchData()
    }, [])

    return (
        <section>
            <PageTitle title="Tickets" lastLink="/" lastTitle="Home" />

            <div className="flex m-b">
                <label>
                    <input type="text" placeholder="Search..." className="inp-n" />
                </label>

                <CreateTicket />
            </div>

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Creator</th>
                            <th>Assigned To</th>
                            <th>Project</th>
                            <th>Deadline</th>
                            <th className="center">Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {tickets && tickets.map((ticket, index) => (
                            <tr key={index}>
                                <td>{ticket.id}</td>
                                <td>{ticket.title}</td>
                                <td className="n-m">
                                    {/* <Link to={ticket.Creator.UserId}>{ticket.Creator.Fullname}</Link> */}
                                </td>
                                <td>
                                    {/* <Link to={ticket.AssignedTo.UserId}>{ticket.AssignedTo.Fullname}</Link> */}
                                </td>
                                <td className="n-m">
                                    <Link to={'/view/project/' + ticket.projectId}>{ticket.projectId}</Link>
                                </td>
                                <td>{FormatDate(ticket.deadline)}</td>
                                <td>
                                    <div className="flex-center">
                                        <Badge pack={1} type={ticket?.Status ?? 0} />
                                    </div>
                                </td>
                                <td>
                                    <Link to={"/ticket/" + ticket.ticketId} className="btn-primary">
                                        <Icon icon="mdi:eye" width="18" height="18" />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </section>
    )
}