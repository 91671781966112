import PageTitle from "../../comps/pageTitle/PageTitle";
import { Icon } from "@iconify-icon/react";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function Customers() {
    const [data] = useState([])

    return (
        <section>
            <PageTitle title="Customers" lastLink="/" lastTitle="Home" />

            <div className="flex m-b">
                <label>
                    <input type="text" placeholder="Search..." className="inp-n" />
                </label>

                {/* <CreateProject /> */}
            </div>

            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Customer</th>
                            <th>Supervisor</th>
                            <th>Status</th>
                            <th>isAlive</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>

                        {data && data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {item.Name}
                                </td>
                                <td>
                                    {item.Customer.Company}
                                </td>
                                <td>
                                    {item.Customer.Firstname + " " + item.Customer.Lastname}
                                </td>
                                <td>
                                    <Link to={"/view/project/" + item.ProjectID} className="btn-primary">
                                        <Icon icon="mdi:eye" width="18" height="18"  style={{color: 'white'}} />
                                    </Link>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </section>
    )
}