export function SetConfig(data) {
    const config = {
        Token: `${data.type} ${data.token}`,
        CorrelationId: data.correlationId
    }

    sessionStorage.setItem(process.env.REACT_APP_AUTH, JSON.stringify(config));

    window.location.reload();

    RenewToken(data.expireIn);
}

export function IsAuth() {
    return sessionStorage.getItem(process.env.REACT_APP_AUTH) !== null;
}

export function GetToken() {
    return JSON.parse(sessionStorage.getItem(process.env.REACT_APP_AUTH))?.Token;
}

export function GetCorelId() {
    return JSON.parse(sessionStorage.getItem(process.env.REACT_APP_AUTH))?.CorrelationId;
}

export function ClearConfig() {
    sessionStorage.removeItem(process.env.REACT_APP_AUTH)
    window.location.reload();
}

function RenewToken(timeInSec) {
    setTimeout(() => {
        console.log("Need new token!")
    }, timeInSec)
}