import toast, { Toaster } from "react-hot-toast";

export function Badge({ pack, type }) {

    // BADGES TYPE:
    // 1. info - light blue
    // 2. primary - dark blue
    // 3. success - green
    // 4. warning - yellow
    // 5. danger - red

    const messages = [
        [
            { message: 'Dead', type: "danger" },
            { message: 'Alive', type: "success" },
        ],
        [
            { message: 'Created', type: "info" },
            { message: 'Developing', type: "primary" },
            { message: 'Testing', type: "primary" },
            { message: 'Complete', type: "success" },
            { message: 'Archived', type: "danger" },
        ],
        [
            { message: 'Incomplete', type: "danger" },
            { message: 'Done', type: "success" },
        ],
    ]

    const item = messages[pack][type];

    return (
        <label className={`badge badge-${item.type}`}>{item.message}</label>
    )
}

export function FormatDate(dateString) {
    const dateObject = new Date(dateString);

    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString().substring(2, 4);
  
    return `${day}/${month}/${year}`;
}

export function TableSkeleton({ columnsCount }) {
    return Array.from({ length: 5 }, (_, index) => (
        <tr key={index}>
            <td colSpan={columnsCount}></td>
        </tr>
    ))
}

export function GetToster() {
    return <Toaster
        position="top-center"
        reverseOrder={false}
    />
}

export function CreateToast(message, isSuccess) {

    const opt = {
        style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
        },
    }

    isSuccess ? 
    toast.success(message, opt)
    :
    toast.error(message, opt)
}