import { Icon } from "@iconify-icon/react";
import PageTitle from "../../../comps/pageTitle/PageTitle";
import "./Article.css";
import Photo from "../../../assets/pictures/BlogDummy.png";
import { useEffect } from "react";

export default function Article() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section>
            <PageTitle title="Article" lastLink="/category" lastTitle="Category" />

            <article>
                <div className="header">
                    <h4 className="title">Beautiful Day with Friends</h4>

                    <div className="info">
                        <p> 
                            <Icon icon="mdi:tag" width="18" height="18" />
                            <span>Programming</span>
                        </p>

                        <p> 
                            <Icon icon="mdi:clipboard-text-date" width="18" height="18" />
                            <span>10 Apr, 2020</span>
                        </p>

                        <p> 
                            <Icon icon="mdi:person" width="18" height="18" />
                            <span>Simon Adam</span>
                        </p>
                        <p> 
                            <Icon icon="mdi:timer" width="18" height="18" />
                            <span>20 min</span>
                        </p>
                    </div>

                    <div className="hr-line" />

                </div>

                <div className="content">

                    <h5>Beautiful Day with Friends</h5>

                    <p>
                        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                    </p>

                    <p>
                        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                    </p>

                    <div>
                        <img src={Photo} alt="post" />
                    </div>

                    <h5>Beautiful Day with Friends</h5>

                    <p>
                        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                    </p>

                    {/* <h6>
                        <span>Link: </span>
                       <a href="#id">Azure Youtube Video</a>
                    </h6>
                    <h6>
                        <span>Link: </span>
                       <a href="#id">Azure Youtube Video</a>
                    </h6> */}
                    <h6>
                        <span>Link: </span>
                       <a href="#id">Azure Youtube Video</a>
                    </h6>

                    <p>
                        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                    </p>

                    <div>
                        <img src={Photo} alt="post" />
                    </div>

                    <h5>Beautiful Day with Friends</h5>

                    <p>
                        Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                    </p>
                </div>
            </article>
        </section>
    )
}