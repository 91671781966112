import { Link } from "react-router-dom";

export default function PageTitle({ title, lastLink, lastTitle }) {
    return (
        <div className="page-title">
            <h4>{title}</h4>

            <ul className="breadcrumb">
                <li>
                    <Link to={lastLink}>{lastTitle}</Link>
                </li>
                <li>/</li>
                <li>
                    <span>{title}</span>
                </li>
            </ul>
        </div>
    )
}