import PageTitle from "../../comps/pageTitle/PageTitle";
import { Icon } from "@iconify-icon/react";
import { useEffect, useState } from "react";
import { GetWorkerConfig, SaveWorkerConfig } from "../../comps/other/ApiManager";

export default function Worker() {
    const [config, setConfig] = useState(null)

    async function fetchData() {
        const response = await GetWorkerConfig()
        setConfig(response)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        return () => fetchData()
    }, [])

    function handleChange(e, i) {
        const { name, value } = e.target

        let newConfig = config;
        newConfig[i][name] = value

        setConfig(newConfig)
    } 

    async function onSave() {
        const response = await SaveWorkerConfig(config)
        setConfig(response)
    }

    return config === null ? <></> :
    (
        <section>
            <PageTitle title="Worker Service" lastLink="/" lastTitle="Home" />

            <div className="table-container m-b">
                <table>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Name</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {config?.map((cfg, i) => (
                            <tr key={i}>
                                <td>{cfg.id}</td>

                                <td>
                                    <input type="text" onChange={(e) => handleChange(e, i)}  name="Name" defaultValue={cfg.name} placeholder="Name..." required />
                                </td>

                                <td>
                                    <input type="text" onChange={(e) => handleChange(e, i)} name="Value" defaultValue={cfg.value} placeholder="Value..." required />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex-end">
                <button onClick={() => setConfig([ ...config, { id: 0, name: '', value: '' }])} type="button" className="btn-primary">
                    <Icon icon="mdi:plus" width="18" height="18" />
                </button>

                <button onClick={() => onSave()} className="btn-primary">Save</button>
            </div>
        </section>
    )
}