import Modal from "../../../comps/modal/Modal";
import { useEffect, useState } from "react";
import { Icon } from "@iconify-icon/react";

function CreateProject() {

    const [isOpen, setOpen] = useState(false);
    const [newProject, setProject] = useState({});
    const [template, setTemplate] = useState(null);

    useEffect(() => {
        async function fetchData() {
            // const response = await CallApi('GET', '/Project/GetTemplate');
            setTemplate("response");
        }
        return () => fetchData()
    }, [])

    async function OnSubmit(e) {
        e.preventDefault()
        console.log(newProject)
        // const result = await CallApi('POST', '/Project/CreateProject', body);
        
        // if (result != null) {
        //     CreateToast(result.Message, true)
            
        //     setTimeout(() => {
        //         window.location.reload()
        //     }, 1000);
        // } else {
        //     CreateToast('Something wrong happend!', false)
        // }
    }

    function ChangeValue(e) {
        const intArray = ["CustomerId", "ParentProject"]
        const boolArray = ["Notifications"]

        const { name, value, checked } = e.target;

        setProject((project) => ({
            ...project,
            [name]: intArray.includes(name) ? parseInt(value) : boolArray.includes(name) ? checked : value === "None" ? null : value,
        }));
    }

    return (
        <>
            <button onClick={() => setOpen(true)} className="btn-primary">New Project</button>

            {isOpen && 
            <Modal>
                <div className="flex p-b b-b">
                    <h3>New Project</h3>

                    <button onClick={() => setOpen(false)} className="btn">
                        <Icon icon="mdi:close" width="26" height="26" />
                    </button>
                </div>

                <form onSubmit={OnSubmit} className="flex-column">
                    <div className="flex-row">
                        <label>
                            <span>Name:</span>
                            <input type="text" name="Name" onChange={ChangeValue} placeholder="Name" required />
                        </label>

                        <label>
                            <span>Customer:</span>

                            <select name="CustomerId" onChange={ChangeValue} required>
                                <option selected disabled>Select</option>
                                {template && template.Customers.map((customer, index) => (
                                    <option key={index} value={customer.Id}>{customer.Company}</option>
                                ))}
                            </select>
                        </label>
                    </div>

                    <label>
                        <span>Description:</span>
                        <textarea name="Description" onChange={ChangeValue} required />
                    </label>

                    <label className="flex">
                        <span>Notification:</span>
                        <div className="checkbox-wrap">
                            <input type="checkbox" name="Notifications" onChange={ChangeValue} />
                        </div>
                    </label>

                    <button className="btn-primary">Create</button>
                </form>
            </Modal>
            }
        </>
    )
}

export default CreateProject;