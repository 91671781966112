import { SetConfig } from "../../comps/other/TokenProvider";
import { PostGetToken } from "../../comps/other/ApiManager";
import Image from "../../assets/pictures/Login.png";
import { useState } from "react";
import "./Login.css";

export default function Login() {

    const [credentials, setCredentias] = useState({});

    async function onLogin(e) {
        e.preventDefault();

        if(localStorage.getItem(process.env.REACT_APP_AUTH) === null) 
        {
            const response = await PostGetToken(credentials);

            if(response !== null) 
                SetConfig(response);
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setCredentias({ ...credentials, [name]: value })
    }

    return (
        <main className="login-page">
            <div className="login-container">
                <div className="top-row">
                    <div className="text">
                        <h5>Welcome Back!</h5>
                        <p>Sign in to continue to Valdo.</p>
                    </div>

                    <img src={Image} alt="login" />
                </div>
                <form onSubmit={onLogin} className="login-form">
                    <label>
                        <span>Email</span>
                        <input type="text" onChange={(e) => handleChange(e)} name="Email" placeholder="Email" required />
                    </label>

                    <label>
                        <span>Password</span>
                        <input type="password" onChange={(e) => handleChange(e)} name="Password" placeholder="Password" required />
                    </label>

                    <div className="btn-group">
                        <button type="submit" className="btn-primary">Login</button>
                    </div>
                </form>
            </div>
        </main>
    )
}

/* { displayError && <h6 className="text-center m-t error">Error: Wrong credentials!</h6> } */
