import { GetArticleCategories, SaveArticle } from "../../../comps/other/ApiManager";
import PageTitle from "../../../comps/pageTitle/PageTitle";
import { Icon } from "@iconify-icon/react";
import { useEffect, useState } from "react";

export default function CreateArticle() {

    const [template, setTemplate] = useState(null)
    const [articleInfo, setArticleInfo] = useState({ Title: '', CategoryId: 0 })
    const [article, setArticle] = useState([])

    useEffect(() => {
        async function fetchData() {
            const response = await GetArticleCategories()
            setTemplate(response)
        }

        return () => fetchData()
    }, [])

    async function onSubmit(e) {
        e.preventDefault();

        const wordPerMinute = 250;

        let wordCount = 0;

        article.forEach((item) => {
            if (['Paragraph', 'Subtitle'].includes(item.Type)) {
                wordCount += item.Data.toString().split(' ').length;
            }
        })

        const totalMinutes = wordCount / wordPerMinute;
        
        let text = '';
        article.forEach((item) => {
            switch (item.Type) {
                case 'Subtitle':
                    text += `<h5>${item.Data}</h5>`;
                    break;
                case 'Paragraph':
                    text += `<p>${item.Data}</p>`;
                    break;
                case 'Image':
                    text += `<div><img src="${item.Data}" alt="post" /></div>`;
                    break;
                case 'Link':
                    const linkSplits = item.Data.split('-')
                    text += `<h6><span>Link: </span><a href="${linkSplits[1]}">${linkSplits[0]}</a></h6>`;
                    break;
                default:
                    break;
            }
        })

        const newArticle = {
            Title: articleInfo.Title,
            CategoryId: articleInfo.CategoryId,
            Text: text,
            Time: parseInt(totalMinutes),
        }

        console.log(newArticle)
        // const result = await SaveArticle(newArticle)
        // console.log(result)
    }

    function handleChange(e) {
        const { name, value} = e.target
        setArticleInfo({ ...articleInfo, [name]: value })
    }

    return (
        <section>
            <PageTitle title="Create Article" lastLink="/wiki" lastTitle="Wiki" />

            <SideMenu setValue={setArticle} value={article} />

            <form onSubmit={onSubmit}>
                <div className="flex">
                    <label>
                        <span>Title</span>
                        <input type="text" onChange={(e) => handleChange(e)} name="Title" placeholder="Title..." required />
                    </label>

                    <label>
                        <span>Category</span>
                        <select onChange={(e) => handleChange(e)} name="CategoryId" required >
                            <option>Select Category</option>
                            {template?.map((cat, i) => (
                                <option value={cat.id} key={i}>{cat.name}</option>
                            ))}
                        </select>
                    </label>
                </div>

                {article.map((item, i) => (
                    <GetItem item={item} key={i} index={i} article={article} setValue={setArticle} />
                ))}

                <div className="flex-end">
                    <button className="btn-primary">Create</button>
                </div>
            </form>
        </section>
    )
}

function SideMenu({ value, setValue }) {
    function onClick(type) {
        setValue([ ...value, { Type: type, Data: "" }])
    }

    return (
        <ul className="create-menu">
            <li>
                <button onClick={() => onClick('Subtitle')} className="btn-primary">
                    <Icon icon="mdi:text-short" width="18" height="18" />
                </button>
                
            </li>
            <li>
                <button onClick={() => onClick('Paragraph')} className="btn-primary">
                    <Icon icon="mdi:text-box-edit" width="18" height="18" />
                </button>
                
            </li>
            <li>
                <button onClick={() => onClick('Link')} className="btn-primary">
                    <Icon icon="mdi:link-edit" width="18" height="18" />
                </button>
            </li>
            <li>
                <button onClick={() => onClick('Image')} className="btn-primary">
                    <Icon icon="mdi:image-edit" width="18" height="18" />
                </button>
            </li>
        </ul>
    )
}

function GetItem({ item, index, article, setValue }) {

    function onChange(value) {
        let newArticle = article
        newArticle[index].Data = value
        setValue(newArticle) 
    }

    function onDelete() {
        const updatedItems = [...article.slice(0, index), ...article.slice(index + 1)];
        setValue(updatedItems);
    }

    switch (item.Type) {
        case 'Subtitle':
            return (
                <div className="article-item">
                    <label>
                        <span>Subtitle</span>

                        <div className="flex">
                            <input type="text" onChange={(e) => onChange(e.target.value)} defaultValue={item.Data} placeholder="Subtitle..." required />

                            <button type="button" onClick={() => onDelete()} className="btn-error">
                                <Icon icon="mdi:trash-can" width="18" height="18" />
                            </button>
                        </div>
                    </label>
                </div>
            )
        case 'Paragraph':
            return (
                <div className="article-item">
                    <label>
                        <span>Paragraph</span>
                        <textarea onChange={(e) => onChange(e.target.value)} defaultValue={item.Data} required />
                    </label>
                    <div className="flex-end">
                        <button type="button" onClick={() => onDelete()}  className="btn-error">
                            <Icon icon="mdi:trash-can" width="18" height="18" />
                        </button>
                    </div>
                </div>
            )
        case 'Image':
            return (
                <div className="article-item">
                    <label>
                        <span>Image</span>

                        <div className="flex">
                            <input type="text" onChange={(e) => onChange(e.target.value)} defaultValue={item.Data} placeholder="Path..." required />

                            <button type="button" onClick={() => onDelete()}  className="btn-error">
                                <Icon icon="mdi:trash-can" width="18" height="18" />
                            </button>
                        </div>
                    </label>
                </div>
            )
        case 'Link':
            return (
                <div className="article-item">
                    <label>
                        <span>Image</span>

                        <div className="flex">
                            <input type="text" onChange={(e) => onChange(e.target.value)} defaultValue={item.Data} placeholder="Name-Link..." required />

                            <button type="button" onClick={() => onDelete()} className="btn-error">
                                <Icon icon="mdi:trash-can" width="18" height="18" />
                            </button>
                        </div>
                    </label>
                </div>
            )
        default:
            return;
    }
}