import { Outlet } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Aside from "../aside/Aside";
import { useState } from "react";
import "./Body.css";

function Body() {
    const [isAsideOpen, setIsAsideOpen] = useState(true)

    const toogleAside = () => setIsAsideOpen(!isAsideOpen)

    return (
        <main className="body">
            <Aside isOpen={isAsideOpen} />

            <div className="content">
                <Header isOpen={isAsideOpen} toogleAside={toogleAside} />

                <Outlet />

                <Footer />
            </div>
        </main>
    )
}

export default Body;