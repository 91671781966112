import PageTitle from "../../comps/pageTitle/PageTitle";
import { Icon } from "@iconify-icon/react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Wiki.css";
import { GetArticleCategories, GetMostViewedArticle } from "../../comps/other/ApiManager";

export default function Wiki() {

    const [categories, setCategories] = useState(null)
    const [topArticles, setTopArticles] = useState(null)

    useEffect(() => {
        async function fetchData() {
            const resultCategory = await GetArticleCategories();
            setCategories(resultCategory)

            const resultArticles = await GetMostViewedArticle(5);
            setTopArticles(resultArticles)
        }

        return () => fetchData()
    }, [])

    return (
        <section>
            <PageTitle title="Wiki" lastLink="/" lastTitle="Home" />

            <div className="flex m-b">
                <form className="search">
                    <label>
                        <input type="text" placeholder="Search..." className="inp-n" />
                    </label>
                    <button className="btn-primary">Search</button>
                </form>

                <Link to={"create-article"} className="btn-primary">Create Article</Link>
            </div>

            <div className="flex-body">
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Category</th>
                                <th>Most Popular</th>
                                <th>Articles</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            {categories && categories.map((item, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        <Link to={'article/' + item.topArticle?.articleId}>{item.topArticle?.name}</Link>
                                    </td>
                                    <td>{item.articleCount}</td>
                                    <td>
                                        <Link to={"category/" + item.id} className="btn-primary">
                                            <Icon icon="mdi:eye" width="18" height="18"  style={{color: 'white'}} />
                                        </Link>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                </div>

                <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Most Popular</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {topArticles && topArticles.map((art, i) => (
                                    <tr key={i}>
                                        <td>
                                            <Link to={"article/" + art.articleId}>{art.name}</Link>
                                        </td>
                                        <td>{art.time} min</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
            </div>
        </section>
    )
}